import { LinkColorIcon } from "../atoms/Link";
import {
  useLocationVendedor,
  useLocationCliente,
} from "../../lib/useLocationLanding";
import PropTypes from "prop-types";
import ButtonPrimary from "components/atoms/Button";

export default function Barra({
  textBarra,
  url,
  textLink,
  colorLink,
  classname,
}) {
  const vendedor = useLocationVendedor();
  const clientes = useLocationCliente();

  return (
    <div
      className={
        "landings relative z-50 flex justify-center align-center items-center flex-col w-full gap-y-px py-10 bg-white min-h-[12rem] lg:mt-8 " +
        classname
      }
    >
      {textBarra ? (
        <div className="px-[5%] lg:px-[10%] text-center">
          <div className="leading-8 text-2xl" dangerouslySetInnerHTML={{ __html: textBarra }}/>
        </div>
      ) : (
        ""
      )}
      {url ? (
        <div className="px-[5%] lg:px-[10%] mt-6">
            <ButtonPrimary
              url={url}
              textButton={textLink}
              backgroundButton={colorLink}
              colorTextButton="ffffff"
            />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

Barra.propTypes = {
  text: PropTypes.string,
  textLink: PropTypes.string,
  url: PropTypes.string,
  colorLink: PropTypes.string,
};
